import SpinnerLoader from "../../components/SpinnerLoader";

export const ContactPage = SpinnerLoader({
  loader: () => import("../ContactPage"),
});
export const ActivationAccount = SpinnerLoader({
  loader: () => import("../ActivateAccount"),
});
export const CreateMapathonPage = SpinnerLoader({
  loader: () => import("../CreateMapathonPage"),
});
export const CreateReviewPage = SpinnerLoader({
  loader: () => import("../CreateReviewPage"),
});
export const CreateTeamPage = SpinnerLoader({
  loader: () => import("../CreateTeamPage"),
});
export const FaqPage = SpinnerLoader({
  loader: () => import("../FaqPage"),
});
export const ForgottenPasswordPage = SpinnerLoader({
  loader: () => import("../ForgottenPasswordPage"),
});
export const MapathonPage = SpinnerLoader({
  loader: () => import("../MapathonPage"),
});
export const MapathonsPage = SpinnerLoader({
  loader: () => import("../MapathonsPage"),
});
export const NotFoundPage = SpinnerLoader({
  loader: () => import("../NotFoundPage"),
});
export const PrivacyPolicyPage = SpinnerLoader({
  loader: () => import("../PrivacyPolicyPage"),
});
export const ResetPasswordPage = SpinnerLoader({
  loader: () => import("../ResetPasswordPage"),
});
export const SignInPage = SpinnerLoader({
  loader: () => import("../SignInPage"),
});
export const SignUpPage = SpinnerLoader({
  loader: () => import("../SignUpPage"),
});
export const SocialAuthPage = SpinnerLoader({
  loader: () => import("../SocialAuthPage"),
});
export const TacPage = SpinnerLoader({
  loader: () => import("../TacPage"),
});
export const TeamPage = SpinnerLoader({
  loader: () => import("../TeamPage"),
});
export const TeamsPage = SpinnerLoader({
  loader: () => import("../TeamsPage"),
});
export const ThankYouReviewPage = SpinnerLoader({
  loader: () => import("../ThankYouReviewPage"),
});
export const UserPage = SpinnerLoader({
  loader: () => import("../UserPage"),
});
export const VenuePage = SpinnerLoader({
  loader: () => import("../VenuePage"),
});
export const VenuesPage = SpinnerLoader({
  loader: () => import("../VenuesPage"),
});
export const DonatePage = SpinnerLoader({
  loader: () => import("../DonatePage"),
});
